import React, { useMemo, useContext, useCallback, useState } from 'react';
import { css } from '@emotion/react';
import { Grid, TextField } from '@material-ui/core';
import { FixedSizeList, areEqual } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { asDateInTZ } from '@/utilities/convertToISO';
import RouteListItem from './RouteListItem';
import { integerFormatter } from '@/constants/formats';
import { useItemQuantity, useTotalCubes } from '@/hooks';
import { MAX_ROUTES, TEMP_ROUTE } from '../constants';
import { PlanningContext } from '../context';
import { useClientUser } from '@/hooks/useClientUser';
import { QUERY_PLANNING_DATA } from '../graphql/queries';
import { useMutation } from '@apollo/client';
import { INSERT_ROUTE, UPDATE_ROUTE_STOPS, INSERT_MULTIPLE_ROUTES } from '../graphql/mutations';
import handleGeocode from '@/utilities/handleGeocode';
import FTLStopHelpers from '@/utilities/FTLStopHelpers';
import { Body1, Body2, GridScrollY, GrowingGrid, PrimaryButtonFullW } from '../blocks';
import { ROUTE_COLORS } from '../constants';
import { format } from 'date-fns';
import { MODALS } from '../constants';
import { formatInTimeZone } from 'date-fns-tz';
import { routeIsMissingTimeframes, routeNeedsRecalculating } from '../util';

const PlanningRoutes = () => {
    const {
        selectedRoute,
        setSelectedRoute,
        deliveryDate,
        setError,
        setModalOpen,
        setNotification,
        state: { orders, routes, variables, selectedDrivers },
        callbacks: { subscribeToMore, setRoutesToSubmitInternal },
    } = useContext(PlanningContext);
    const {
        user_id,
        default_end_location,
        drivers,
        trucks,
        preferences_next_day_returns,
        preferences_bypass_optimize,
        circles,
    } = useClientUser();

    const [numRoutes, setNumRoutes] = useState(1);

    const routesToDisplay = useMemo(() => {
        if (Object.values(selectedDrivers).some((x) => x)) {
            return routes.filter((r) => !!selectedDrivers[r.driver_id]);
        }
        return routes;
    }, [drivers, routes, selectedDrivers]);

    const [addRoute, { loading: addRouteLoading }] = useMutation(INSERT_MULTIPLE_ROUTES, {
        update: (cache, { data: { insert_routes } }) => {
            const routeValues = insert_routes.returning;
            const newRoutes = Array.isArray(routeValues) ? routeValues : [routeValues];
            const tempRoutes = newRoutes.filter((route) => !isFinite(route?.route_number));
            const isTemp = tempRoutes.length > 0;

            cache.updateQuery(
                {
                    query: QUERY_PLANNING_DATA,
                    variables,
                },
                (data) => ({
                    ...data,
                    routes: [...data.routes.filter((route) => isFinite(route.route_number) || isTemp), ...newRoutes],
                })
            );
        },
    });

    const [updateStops, { loading: stopsLoading }] = useMutation(UPDATE_ROUTE_STOPS);

    const linkedOrders = useMemo(() => {
        return routes.reduce((acc, route) => [...acc, ...route.orders.map((mapping) => mapping.order)], []);
    }, [routes]);

    // Calculate total items and total dropoffs for the routes.
    const totalItems = useItemQuantity(linkedOrders);
    const totalVolume = useTotalCubes(linkedOrders);
    const totalDropoffs = useMemo(() => {
        return routes.reduce((acc, route) => {
            return (
                acc + (route?.stopsByRouteId?.filter((stop) => FTLStopHelpers.isCustomerStop(stop, route))?.length || 0)
            );
        }, 0);
    }, [routes]);

    const getEndAddressInfo = async (address, route) => {
        const addressInfo = await handleGeocode({
            address: `${address.business_address}, ${address.business_city}, ${address.business_state}, ${address.business_zip}`,
        });
        if (addressInfo.lat && addressInfo.lng) {
            const newStops = FTLStopHelpers.addEnd(route, addressInfo);
            updateStops({
                variables: {
                    route_id: route.route_id,
                    route_update: { need_to_optimize: true },
                    ...FTLStopHelpers.gqlStopUpdates(newStops, route),
                },
                onError: (error) => {
                    setError(error, 'Error setting route default end location');
                },
            });
        }
    };

    const addRouteHandler = async () => {
        const displayedRouteColors = new Set(routesToDisplay.map((route) => route.route_color));
        const allowedRouteColors = new Set(ROUTE_COLORS);

        // pick colors not already assigned
        const availableColors = [
            ...new Set([...allowedRouteColors].filter((color) => !displayedRouteColors.has(color))),
        ];

        const newRoutes = Array.from({ length: numRoutes }, (_, i) => {
            return {
                shipper_id: user_id,
                scheduled_delivery: asDateInTZ(deliveryDate).toISOString(),
                route_color: availableColors[i % availableColors.length],
                finish_returns_next_day: preferences_next_day_returns,
                status: 'planning',
                source_form: 'PLANNING',
                need_to_optimize: true,
                planning: true,
            };
        });

        addRoute({
            optimisticResponse: {
                insert_routes: {
                    returning: newRoutes.map((routeInsert) => ({
                        ...TEMP_ROUTE,
                        ...routeInsert,
                        stopsByRouteId: [],
                        orders: [],
                        truck: {},
                        __typename: 'routes',
                    })),
                    __typename: 'routes_mutation_response',
                },
            },
            variables: { routes: newRoutes },
            onError: (error) => {
                setError(error, 'Error adding new route');
            },
            onCompleted: (routeData) => {
                // if (truckDetails?.length > 0 && routes.length + 1 >= truckDetails?.length + 1) {
                //     setModalOpen(MODALS.DAILY_TRUCKS_EXCEEDED);
                // }
            },
        });
    };

    const lockAllRoutes = () => {
        const pendingRoutes = routesToDisplay.filter((r) => !r.shell && r.status === 'planning');

        if (pendingRoutes.some((r) => routeIsMissingTimeframes(r))) {
            setNotification({
                severity: 'warning',
                message: 'Route is missing time frames. Please click "Map Route" or "Optimize" to add them.',
            });
            return;
        } else if (pendingRoutes.some((r) => routeNeedsRecalculating(r))) {
            setNotification({
                severity: 'warning',
                message:
                    'Stops have been rearranged. Please click "Map Route" or "Optimize" to recalculate timeframes.',
            });
            return;
        } else if (pendingRoutes.some((r) => r.need_to_optimize && !preferences_bypass_optimize)) {
            setNotification({
                severity: 'warning',
                message:
                    'You have made changes to this route that require timeframes to be recalculated. Please click "Map Route" or "Optimize" to recalculate timeframes.',
            });
            return;
        }

        setRoutesToSubmitInternal(pendingRoutes);
        setModalOpen(MODALS.ROUTE_INTERNAL);
    };

    const route = useCallback(
        ({ index, data, style }) => {
            if (index > data.length - 1) {
                return null;
            }

            const route = data[index];
            return (
                <RouteListItem
                    style={style}
                    key={route.route_id}
                    routeIndex={index}
                    route={route}
                    drivers={drivers}
                    subscribeToMore={subscribeToMore}
                />
            );
        },
        [drivers, subscribeToMore]
    );

    return (
        <>
            <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                css={css`
                    padding: 1rem 1.5rem 1rem 1rem;
                    border-bottom: 1px solid #e2e2e2;
                    border-top: 1px solid #e2e2e2;
                `}
                onClick={() => setSelectedRoute(null)}
            >
                <Grid item>
                    <Body1>{`${routes.length} Route${routes.length !== 1 ? 's' : ''}`}</Body1>
                </Grid>
                <Grid item>
                    <Body2>
                        {[
                            `${integerFormatter.format(totalItems)} items`,
                            `${integerFormatter.format(totalVolume)} cu ft.`,
                        ].join(' / ')}
                    </Body2>
                </Grid>
                <Grid item>
                    <Body2>{`${totalDropoffs} stop${totalDropoffs !== 1 ? 's' : ''}`}</Body2>
                </Grid>
            </Grid>
            <Grid
                container
                css={css`
                    display: flex;
                    flex-grow: 1;
                    flex-basis: 0;
                `}
            >
                <AutoSizer>
                    {({ height, width }) => {
                        return (
                            <FixedSizeList
                                overscanCount={20}
                                height={height}
                                itemData={routesToDisplay}
                                itemCount={routesToDisplay.length + 1}
                                itemSize={220}
                                width={width}
                            >
                                {route}
                            </FixedSizeList>
                        );
                    }}
                </AutoSizer>
            </Grid>

            <Grid
                container
                direction="row"
                css={css`
                    flex-wrap: nowrap;
                    padding: 1rem;
                    align-items: center;
                `}
            >
                <Grid
                    item
                    css={css`
                        width: 60px;
                        margin-right: 1rem;
                    `}
                >
                    <TextField
                        label="Qty"
                        type="number"
                        variant="outlined"
                        value={numRoutes || 1}
                        onChange={(e) => setNumRoutes(e.target.value)}
                        size="small"
                        fullWidth
                    />
                </Grid>
                <Grid
                    item
                    css={css`
                        flex-grow: 1;
                    `}
                >
                    <PrimaryButtonFullW
                        onClick={addRouteHandler}
                        disabled={routes.length >= MAX_ROUTES || addRouteLoading}
                    >
                        Add Routes
                    </PrimaryButtonFullW>
                </Grid>
                {circles?.['autoconfirm-all-stops'] ? (
                    <Grid
                        item
                        css={css`
                            flex-grow: 1;
                            margin-left: 1rem;
                        `}
                    >
                        <PrimaryButtonFullW onClick={lockAllRoutes}>Lock All Routes</PrimaryButtonFullW>
                    </Grid>
                ) : null}
            </Grid>
        </>
    );
};

export default PlanningRoutes;
