import React, { useState, useEffect, useMemo } from 'react';
import { compose } from 'recompose';
import { useParams } from 'react-router';
import { Button, Form, Modal, Row, Col } from 'react-bootstrap';
import { Grid, TextField, MenuItem } from '@material-ui/core';
import { UPDATE_CLIENT_ADMIN_CARRIER, DEACTIVATE_CLIENT_ADMIN } from '@/graphql/mutations/clients';
import { QUERY_BY_PK_WITH_USER } from '@/graphql/queries/clients';
import Blacklist from '../../Blacklist';
import { Rating } from '@material-ui/lab';
import * as Sentry from '@sentry/react';
import dateFnsFormat from 'date-fns/format';
import withErrorBoundary from '../../ErrorBoundary';
import AdminFeatureFlags from '../AdminFeatureFlags/index.js';
import withAdminRights from '@/components/Auth/withAdminRights';
import { OnwardCheckbox } from '@/components/ShipmentForm/blocks';
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import AdminCarrierDocument from './AdminCarrierDocument';
import uploadPhotoAsync from '@/utilities/uploadPhotoAsync';
import { UPDATE_CARRIER_DOCUMENT, UPDATE_DOCUMENT_EXPIRY } from './mutations';
import { UPSERT_PRICING_OVERRIDES } from '../../Account/Tariffs/graphql';
import { LIST_ADMIN_TARIFFS_BY_CLIENT_ID } from '@/graphql/queries/pricing_tariffs';
import { getTariffName } from '../../Account/Tariffs/utils';
import { AGGREGATE_RATING_BY_REVIEWEE } from '@/graphql/queries/ratings';
import { toNational, toE164, validate } from '@/utilities/formatPhoneNumber';
import { ClientSelect } from '@/components/ShipmentForm/modals/InputFields';
import { css } from '@emotion/react';
import { SHIPPER_PARTNERS } from './queries';
import { UPDATE_CLIENT_BY_PK } from '@/graphql/mutations/clients';
import { useNavigate } from 'react-router-dom';
import EventSubscriptions from '../AdminShipperDetails/EventSubscriptions';
import { PrimaryButton } from '@/styles/blocks';
import { v4 as uuid } from 'uuid';

const AdminCarrierDetails = () => {
    const { carrier_id } = useParams();
    const [business, setBusiness] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [billingEmail, setBillingEmail] = useState('');
    const [warehouseAddress, setWarehouseAddress] = useState('');
    const [warehouseCity, setWarehouseCity] = useState('');
    const [warehouseState, setWarehouseState] = useState('');
    const [warehouseZip, setWarehouseZip] = useState('');
    const [billingAddress, setBillingAddress] = useState('');
    const [billingCity, setBillingCity] = useState('');
    const [billingState, setBillingState] = useState('');
    const [billingZip, setBillingZip] = useState('');
    const [paymentType, setPaymentType] = useState('');
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [rating, setRating] = useState(null);
    const [validated, setValidated] = useState(false);
    const [userObject, setUserObject] = useState({});
    const [blacklist, setBlacklist] = useState([]);
    const [ein, setEin] = useState('');
    const [dot, setDot] = useState('');
    const [mcNum, setMcNum] = useState('');
    const [billcomVendorId, setBillcomVendorId] = useState('');
    const [onwardform, setOnwardform] = useState('');
    const [onwardformUploaded, setOnwardformUploaded] = useState('');
    const [onwardformExpiration, setOnwardformExpiration] = useState('');
    const [insuranceform, setInsuranceform] = useState('');
    const [insuranceformUploaded, setInsuranceformUploaded] = useState('');
    const [insuranceformExpiration, setInsuranceformExpiration] = useState('');
    const [workersCompIns, setWorkersCompIns] = useState('');
    const [workersCompInsUploaded, setWorkersCompInsUploaded] = useState('');
    const [workersCompInsExpiration, setWorkersCompInsExpiration] = useState('');
    const [wform, setWform] = useState('');
    const [wformUploaded, setWformUploaded] = useState('');
    const [wformExpiration, setWformExpiration] = useState('');
    const [carrier, setCarrier] = useState({});
    const [partnerShipperId, setPartnerShipperId] = useState(null);

    const navigate = useNavigate();

    useQuery(QUERY_BY_PK_WITH_USER, {
        variables: { client_id: carrier_id },
        onCompleted: (data) => {
            const carrierInfo = data.clients_by_pk;
            setBusiness(carrierInfo.business_name);
            setUsername(carrierInfo.user.username);
            setEmail(carrierInfo.email);
            setBillingAddress(carrierInfo.billing_address);
            setBillingEmail(carrierInfo.billing_email);
            setBillingCity(carrierInfo.billing_city);
            setBillingState(carrierInfo.billing_state);
            setBillingZip(carrierInfo.billing_zip);
            setWarehouseAddress(carrierInfo.business_address);
            setWarehouseCity(carrierInfo.business_city);
            setWarehouseState(carrierInfo.business_state);
            setWarehouseZip(carrierInfo.business_zip);
            setEin(carrierInfo.business_ein);
            setDot(carrierInfo.dot_number);
            setMcNum(carrierInfo.mc_num);
            setBillcomVendorId(carrierInfo.billcom_vendor_id);
            setUserObject(carrierInfo.user);
            setOnwardform(carrierInfo.onward_form);
            setOnwardformUploaded(carrierInfo.onward_form_uploaded_at);
            setOnwardformExpiration(carrierInfo.onward_form_expiration);
            setInsuranceform(carrierInfo.insurance_form);
            setInsuranceformUploaded(carrierInfo.insurance_form_uploaded_at);
            setInsuranceformExpiration(carrierInfo.insurance_form_expiration);
            setWform(carrierInfo.w_form);
            setWformUploaded(carrierInfo.w_form_uploaded_at);
            setWformExpiration(carrierInfo.w_form_expiration);
            setWorkersCompIns(carrierInfo.workers_comp_form);
            setWorkersCompInsUploaded(carrierInfo.workers_comp_form_uploaded_at);
            setWorkersCompInsExpiration(carrierInfo.workers_comp_form_expiration);
            setBlacklist(carrierInfo.blacklisted_clients);
            setPaymentType(carrierInfo.payment_type);
            setCarrier(carrierInfo);
            setPartnerShipperId(carrierInfo.partner_shipper_id);

            const _phone = carrierInfo.business_phone
                ? validate(carrierInfo.business_phone)
                    ? toNational(carrierInfo.business_phone)
                    : carrierInfo.business_phone
                : '';
            setPhone(_phone);
        },
        onError: (error) => Sentry.captureException(error),
    });

    const {
        loading: partneredShipperOptionsLoading,
        error: partneredShipperOptionsError,
        data: partneredShipperOptionsData,
    } = useQuery(SHIPPER_PARTNERS, {
        variables: {
            allow_test: carrier?.test_acc ? { test_acc: { _eq: true } } : { test_acc: { _eq: false } },
            carrier_id: carrier_id,
        },
        onError: (error) => {
            console.log(error);
        },
    });

    const [fetchTariffs, { data: tariffsResp, loading }] = useLazyQuery(LIST_ADMIN_TARIFFS_BY_CLIENT_ID);

    const [tariffs, overrides] = useMemo(() => {
        if (tariffsResp) {
            const { tariffs, overrides } = tariffsResp;
            const grouped = tariffs.reduce((acc, tariff) => {
                acc[tariff.algo_type] = [...(acc[tariff.algo_type] || []), tariff];
                return acc;
            }, {});

            return [grouped, overrides];
        }

        return [[], []];
    }, [tariffsResp]);

    useEffect(() => {
        fetchTariffs({
            variables: {
                client_id: carrier_id,
            },
        });
    }, [carrier_id]);

    useQuery(AGGREGATE_RATING_BY_REVIEWEE, {
        variables: {
            reviewee_id: carrier_id,
        },
        onCompleted: ({ results }) => {
            setRating((results?.aggregate?.avg?.rating || 0).toFixed(1));
        },
        onError: (error) => Sentry.captureException(error),
    });

    const [updateAccountInfo, { loading: submitting }] = useMutation(UPDATE_CLIENT_ADMIN_CARRIER, {
        onError: (error) => {
            Sentry.captureException(error);
        },
    });

    const [deactivateAccount] = useMutation(DEACTIVATE_CLIENT_ADMIN, {
        onError: (error) => {
            Sentry.captureException(error);
        },
        refetchQueries: [{ query: QUERY_BY_PK_WITH_USER, variables: { client_id: carrier_id } }],
    });

    const [updateCarrierDoc] = useMutation(UPDATE_CARRIER_DOCUMENT, {
        onError: (error) => {
            Sentry.captureException(error);
        },
    });

    const [updateDocExpiry] = useMutation(UPDATE_DOCUMENT_EXPIRY, {
        onError: (error) => {
            Sentry.captureException(error);
        },
    });

    const [upsertPo] = useMutation(UPSERT_PRICING_OVERRIDES, {
        update: (cache, { data: { updated } }) => {
            cache.updateQuery(
                {
                    query: LIST_ADMIN_TARIFFS_BY_CLIENT_ID,
                    variables: {
                        client_id: carrier_id,
                    },
                },
                (data) => {
                    let { tariffs, overrides } = data;
                    let clone = [...overrides];
                    const [update] = updated;

                    const idx = clone.findIndex((po) => po.pricing_override_id === update.pricing_override_id);
                    if (idx >= 0) {
                        clone[idx] = update;
                    } else {
                        clone = [...clone, update];
                    }

                    return {
                        tariffs,
                        overrides: clone,
                    };
                }
            );
        },
        onError: (e) => {
            Sentry.captureException(e);
        },
    });

    const handleSave = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === true) {
            updateAccountInfo({
                variables: {
                    client_id: carrier_id,
                    business_name: business,
                    username: username,
                    email: email,
                    business_phone: validate(phone) ? toE164(phone) : phone,
                    business_address: warehouseAddress,
                    business_city: warehouseCity,
                    business_state: warehouseState,
                    business_zip: warehouseZip,
                    billing_email: billingEmail,
                    billing_address: billingAddress,
                    billing_city: billingCity,
                    billing_state: billingState,
                    billing_zip: billingZip,
                    business_ein: ein,
                    dot_number: dot,
                    mc_num: mcNum,
                    payment_type: paymentType,
                    billcom_vendor_id: billcomVendorId,
                },
            });
        }

        setValidated(true);
    };

    const handleDeactivate = () => {
        setShowDeleteModal(false);
        deactivateAccount({
            variables: {
                client_id: carrier_id,
                deactivated: true,
            },
        })
            .then(() => {
                navigate('/admin/users');
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const handleExpUpdate = (day, formType) => {
        if (!day) return;

        let day_format = dateFnsFormat(day, 'MM/dd/yyyy');

        updateDocExpiry({
            variables: {
                client_id: carrier_id,
                _set:
                    formType === 'insuranceformExpiration'
                        ? {
                              insurance_form_expiration: day_format,
                          }
                        : {
                              workers_comp_form_expiration: day_format,
                          },
            },
        });
    };

    const uploadDoc = async (e) => {
        const fileName = e.target.name;

        try {
            let file = await URL.createObjectURL(e.target.files[0]);
            let fbFile = await uploadPhotoAsync(file, 'carrier', 'pdf');

            let variables = {};

            switch (fileName) {
                case 'onwardform':
                    variables = {
                        onward_form: fbFile,
                        onward_form_uploaded_at: new Date().toISOString(),
                    };

                    break;
                case 'insuranceform':
                    variables = {
                        insurance_form: fbFile,
                        insurance_form_uploaded_at: new Date().toISOString(),
                    };

                    break;
                case 'workersCompIns':
                    variables = {
                        workers_comp_form: fbFile,
                        workers_comp_form_uploaded_at: new Date().toISOString(),
                    };

                    break;
                case 'wform':
                    variables = {
                        w_form: fbFile,
                        w_form_uploaded_at: new Date().toISOString(),
                    };

                    break;
                default:
                    console.log('improper file name');
                    break;
            }
            updateCarrierDoc({
                variables: {
                    client_id: carrier_id,
                    _set: variables,
                },
            });
        } catch (e) {
            Sentry.captureException(e);
        }
    };

    const [updateClient, { loading: loadingClientUpdate }] = useMutation(UPDATE_CLIENT_BY_PK, {
        onError: (e) => console.log(e),
    });

    const handleSelectShipperPartner = (shipperId) => {
        updateClient({
            variables: {
                client_id: carrier_id,
                update: {
                    partner_shipper_id: shipperId,
                },
            },
            onCompleted: () => {
                setPartnerShipperId(shipperId);
            },
        });
    };

    return (
        <div className="mx-2">
            <EmailModal show={showEmailModal} onHide={() => setShowEmailModal(false)} />
            <DeleteModal
                handleDeactivate={handleDeactivate}
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
            />
            <h1>Admin Carrier Profile</h1>
            <hr />
            <div
                css={css`
                    padding-bottom: 35px;
                `}
            >
                <p className="">Database UID: {carrier_id}</p>
                <p className="">Firebase/Auth ID: {userObject.legacy_user_id}</p>

                <div className="d-flex">
                    <p className="mb-3">Rating: </p>
                    {rating && <Rating readOnly value={rating} precision={0.5} />}
                    <p className="ms-2">{rating || 'No ratings yet'}</p>
                </div>
                <Form noValidate validated={validated} onSubmit={handleSave}>
                    <Form.Group className="my-2 d-flex align-items-center flex-wrap">
                        <Form.Label className="Admin-label-width">Business Name:</Form.Label>
                        <Form.Control
                            className="flex-1"
                            type="text"
                            placeholder="Business"
                            value={business}
                            onChange={(e) => setBusiness(e.target.value)}
                            required
                        />
                        <Form.Control.Feedback type="invalid" width="100%">
                            A business name is required.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="my-2 d-flex align-items-center flex-wrap">
                        <Form.Label className="Admin-label-width">Username:</Form.Label>
                        <Form.Control
                            className="flex-1"
                            type="text"
                            placeholder="Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                        <Form.Control.Feedback type="invalid" width="100%">
                            A username is required.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="my-2 d-flex align-items-center flex-wrap">
                        <Form.Label className="Admin-label-width">Phone:</Form.Label>
                        <Form.Control
                            className="flex-1"
                            type="text"
                            placeholder="Phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            required
                        />
                        <Form.Control.Feedback type="invalid" width="100%">
                            A phone number is required.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="my-2 d-flex align-items-center flex-wrap">
                        <Form.Label className="Admin-label-width">Primary Email:</Form.Label>
                        <Form.Control
                            className="flex-1"
                            type="text"
                            placeholder="Email"
                            value={email}
                            onClick={() => setShowEmailModal(true)}
                        />
                        <Form.Control.Feedback type="invalid" width="100%">
                            An email is required.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="my-2 d-flex align-items-center">
                        <Form.Label className="Admin-label-width">Billing Email:</Form.Label>
                        <Form.Control
                            className="flex-1"
                            type="text"
                            placeholder="Billing Email"
                            value={billingEmail}
                            onChange={(e) => setBillingEmail(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="my-2 d-flex align-items-center">
                        <Form.Label className="Admin-label-width">Billing Address:</Form.Label>
                        <Form.Control
                            className="flex-1"
                            type="text"
                            placeholder="Billing Address"
                            value={billingAddress}
                            onChange={(e) => setBillingAddress(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="my-2 d-flex align-items-center">
                        <Form.Label className="Admin-label-width">Billing City:</Form.Label>
                        <Form.Control
                            className="flex-1"
                            type="text"
                            placeholder="Billing City"
                            value={billingCity}
                            onChange={(e) => setBillingCity(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="my-2 d-flex align-items-center">
                        <Form.Label className="Admin-label-width">Billing State:</Form.Label>
                        <Form.Control
                            className="flex-1"
                            type="text"
                            placeholder="Billing State"
                            value={billingState}
                            onChange={(e) => setBillingState(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="my-2 d-flex align-items-center">
                        <Form.Label className="Admin-label-width">Billing Zip:</Form.Label>
                        <Form.Control
                            className="flex-1"
                            type="text"
                            placeholder="Billing Zip"
                            value={billingZip}
                            onChange={(e) => setBillingZip(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="my-2 d-flex align-items-center flex-wrap">
                        <Form.Label className="Admin-label-width">Warehouse Address:</Form.Label>
                        <Form.Control
                            className="flex-1"
                            type="text"
                            placeholder="Address"
                            value={warehouseAddress}
                            onChange={(e) => setWarehouseAddress(e.target.value)}
                            required
                        />
                        <Form.Control.Feedback type="invalid" width="100%">
                            An address is required.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="my-2 d-flex align-items-center flex-wrap">
                        <Form.Label className="Admin-label-width">Warehouse City:</Form.Label>
                        <Form.Control
                            className="flex-1"
                            type="text"
                            placeholder="City"
                            value={warehouseCity}
                            onChange={(e) => setWarehouseCity(e.target.value)}
                            required
                        />
                        <Form.Control.Feedback type="invalid" width="100%">
                            An city is required.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="my-2 d-flex align-items-center flex-wrap">
                        <Form.Label className="Admin-label-width">Warehouse State:</Form.Label>
                        <Form.Control
                            className="flex-1"
                            type="text"
                            placeholder="State"
                            value={warehouseState}
                            onChange={(e) => setWarehouseState(e.target.value)}
                            required
                        />
                        <Form.Control.Feedback type="invalid" width="100%">
                            An State is required.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="my-2 d-flex align-items-center flex-wrap">
                        <Form.Label className="Admin-label-width">Warehouse Zip:</Form.Label>
                        <Form.Control
                            className="flex-1"
                            type="text"
                            placeholder="Zip"
                            value={warehouseZip}
                            onChange={(e) => setWarehouseZip(e.target.value)}
                            required
                        />
                        <Form.Control.Feedback type="invalid" width="100%">
                            An Zip is required.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="my-2 d-flex align-items-center flex-wrap">
                        <Form.Label className="Admin-label-width">EIN:</Form.Label>
                        <Form.Control
                            className="flex-1"
                            type="text"
                            placeholder="EIN"
                            value={ein}
                            onChange={(e) => setEin(e.target.value)}
                            required
                        />
                        <Form.Control.Feedback type="invalid" width="100%">
                            An EIN is required.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="my-2 d-flex align-items-center flex-wrap">
                        <Form.Label className="Admin-label-width">Dot Number:</Form.Label>
                        <Form.Control
                            className="flex-1"
                            type="text"
                            placeholder="Dot Number"
                            value={dot}
                            onChange={(e) => setDot(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid" width="100%">
                            Dot Number is required.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="my-2 d-flex align-items-center flex-wrap">
                        <Form.Label className="Admin-label-width">MC Number:</Form.Label>
                        <Form.Control
                            className="flex-1"
                            type="text"
                            placeholder="MC Number"
                            value={mcNum}
                            onChange={(e) => setMcNum(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid" width="100%">
                            MC number is required.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="my-2 d-flex align-items-center flex-wrap">
                        <Form.Label className="Admin-label-width">Bill.com Vendor ID:</Form.Label>
                        <Form.Control
                            className="flex-1"
                            type="text"
                            placeholder="Bill.com Vendor ID"
                            value={billcomVendorId}
                            onChange={(e) => setBillcomVendorId(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid" width="100%">
                            MC number is required.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="my-2 d-flex align-items-center" controlId="formHorizontalPhone">
                        <Row className="w-50">
                            <Form.Label className="Admin-label-width">Payment Type:</Form.Label>
                            <Col>
                                <Form.Control
                                    label="payment type"
                                    as="select"
                                    style={{
                                        width: '100%',
                                        border: 'solid',
                                        borderWidth: 'thin',
                                        borderRadius: 'unset',
                                        marginTop: 0,
                                        marginRight: '25%',
                                    }}
                                    className="form-select"
                                    value={paymentType}
                                    defaultValue={paymentType}
                                    placeholder="Payment Type"
                                    onChange={(e) => setPaymentType(e.target.value)}
                                >
                                    <option key="1" value="">
                                        --
                                    </option>
                                    <option key="2" value="Invoice">
                                        Invoice
                                    </option>
                                    <option key="3" value="CreditCard">
                                        Credit Card
                                    </option>
                                </Form.Control>
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group
                        className="d-flex justify-content-center align-items-center"
                        style={{ marginBottom: '30px' }}
                    >
                        <Button type="submit" className="mx-3">
                            Save Changes
                        </Button>
                        <Button onClick={() => setShowDeleteModal(true)} variant="danger">
                            Delete Account
                        </Button>
                    </Form.Group>
                </Form>
                <hr></hr>

                <Grid direction="column" container>
                    <Grid
                        direction="row"
                        container
                        css={css`
                            margin-bottom: 8px;
                        `}
                    >
                        Marketplace Tariff(s)
                    </Grid>
                    {(overrides || [{}]).map((override) => {
                        return (
                            <Grid
                                direction="row"
                                container
                                css={css`
                                    margin-bottom: 12px;
                                `}
                            >
                                <Grid
                                    direction="column"
                                    container
                                    css={css`
                                        flex: 1;
                                        flex-basis: 0;
                                        margin-right: 20px;
                                    `}
                                >
                                    <TextField
                                        fullWidth
                                        select
                                        variant="outlined"
                                        label="Type"
                                        size="small"
                                        value={override.algo_type || 'DEFAULT'}
                                        onChange={(e) => {
                                            upsertPo({
                                                variables: {
                                                    override: {
                                                        pricing_override_id: override.pricing_override_id,
                                                        client_id: carrier_id,
                                                        partner_client_id: null,
                                                        tariff_id: null,
                                                        algo_type: e.target.value,
                                                        oms: false,
                                                    },
                                                },
                                            });
                                        }}
                                    >
                                        {[
                                            'BUNGII',
                                            'FRAYT',
                                            'DEFAULT',
                                            'LIVING_SPACES',
                                            'ROCKET_SHIPPING',
                                            'ONWARD_CONSOLIDATED',
                                        ].map((type) => (
                                            <MenuItem key={type} value={type}>
                                                {type}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid
                                    direction="column"
                                    container
                                    css={css`
                                        flex: 1;
                                        flex-basis: 0;
                                        margin-right: 20px;
                                    `}
                                >
                                    <TextField
                                        fullWidth
                                        select
                                        variant="outlined"
                                        label="Tariff"
                                        size="small"
                                        value={override.tariff_id || ''}
                                        onChange={(e) => {
                                            upsertPo({
                                                variables: {
                                                    override: {
                                                        pricing_override_id: override.pricing_override_id,
                                                        client_id: carrier_id,
                                                        partner_client_id: null,
                                                        tariff_id: e.target.value,
                                                        algo_type: override?.algo_type || 'DEFAULT',
                                                        oms: false,
                                                    },
                                                },
                                            });
                                        }}
                                    >
                                        {(tariffs[override.algo_type] || []).map((tariff, idx) => (
                                            <MenuItem key={tariff.tariff_id} value={tariff.tariff_id}>
                                                {getTariffName(tariff, idx)}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid
                                    direction="column"
                                    container
                                    css={css`
                                        flex: 1;
                                        flex-basis: 0;
                                    `}
                                >
                                    <OnwardCheckbox
                                        label={'Enable Fuel Surcharge'}
                                        checked={!!override.add_fuel_surcharge}
                                        onChange={(e) =>
                                            upsertPo({
                                                variables: {
                                                    override: {
                                                        pricing_override_id: override.pricing_override_id,
                                                        client_id: carrier_id,
                                                        partner_client_id: null,
                                                        algo_type: override?.algo_type || 'DEFAULT',
                                                        oms: false,
                                                        add_fuel_surcharge: e.target.checked,
                                                    },
                                                },
                                            })
                                        }
                                    />
                                </Grid>
                            </Grid>
                        );
                    })}
                    <Grid
                        container
                        css={css`
                            margin-bottom: 24px;
                        `}
                    >
                        <PrimaryButton
                            disabled={overrides?.length > 0}
                            onClick={() =>
                                upsertPo({
                                    variables: {
                                        override: {
                                            pricing_override_id: uuid(),
                                            client_id: carrier_id,
                                            partner_client_id: null,
                                            algo_type: 'ONWARD_CONSOLIDATED',
                                            tariff_id: null,
                                            oms: false,
                                        },
                                    },
                                })
                            }
                        >
                            Add Tariff
                        </PrimaryButton>
                    </Grid>
                </Grid>

                <hr></hr>

                <AdminCarrierDocument
                    title="ONWARD FORM"
                    name="onwardform"
                    uploadDoc={uploadDoc}
                    form={onwardform}
                    formUploaded={onwardformUploaded}
                />
                <AdminCarrierDocument
                    title="GENERAL INSURANCE FORM"
                    name="insuranceform"
                    uploadDoc={uploadDoc}
                    form={insuranceform}
                    showFormExp={true}
                    handleExpUpdate={handleExpUpdate}
                    formExp={insuranceformExpiration}
                    formExpName="insuranceformExpiration"
                    formUploaded={insuranceformUploaded}
                />
                <AdminCarrierDocument
                    title="WORKERS' COMP INSURANCE FORM"
                    name="workersCompIns"
                    uploadDoc={uploadDoc}
                    form={workersCompIns}
                    showFormExp={true}
                    handleExpUpdate={handleExpUpdate}
                    formExp={workersCompInsExpiration}
                    formExpName="workersCompInsExpiration"
                    formUploaded={workersCompInsUploaded}
                />
                <AdminCarrierDocument
                    title="W9 FORM"
                    name="wform"
                    uploadDoc={uploadDoc}
                    form={wform}
                    formUploaded={wformUploaded}
                />

                <AdminFeatureFlags
                    client={carrier}
                    uid={carrier_id}
                    userObject={userObject}
                    setUserObject={setUserObject}
                />
                <hr></hr>
                <h5 className="mt-3">Blacklist:</h5>
                <div>Choose companies to blacklist for this carrier</div>
                <div className="mb-3">
                    <Blacklist blacklistType="carrier" currentUser={carrier_id} clientBlacklist={blacklist} />
                </div>

                <hr />
                <h5>Partnered Shipper</h5>
                <div>Choose a shipper that this carrier will be partnered with</div>
                <ClientSelect
                    value={partnerShipperId}
                    options={partneredShipperOptionsData?.results || []}
                    disabled={partneredShipperOptionsLoading || loadingClientUpdate}
                    onChange={(shipperId) => handleSelectShipperPartner(shipperId)}
                    allowEmpty={true}
                    type="Shipper"
                    css={css`
                        margin-left: 0.5rem;
                    `}
                />
            </div>

            <hr />

            <EventSubscriptions client_id={carrier_id} />

            <hr />
        </div>
    );
};

const EmailModal = (props) => {
    const { show, onHide } = props;

    return (
        <Modal className="mt-5" show={show} onHide={onHide}>
            <Modal.Header>Email Change</Modal.Header>

            <Modal.Body>
                Changing the primary email will alter how the user logs in, and should only be done by the user.
            </Modal.Body>
        </Modal>
    );
};

const DeleteModal = (props) => {
    const { show, onHide, handleDeactivate } = props;

    return (
        <Modal className="mt-5" show={show} onHide={onHide}>
            <Modal.Header>Delete Account</Modal.Header>

            <Modal.Body>
                Please confirm the deletion of this account.
                <div className="d-flex justify-content-center" style={{ marginTop: '8px' }}>
                    <Button type="submit" className="mx-3" onClick={handleDeactivate}>
                        Confirm
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default compose(withAdminRights, withErrorBoundary)(AdminCarrierDetails);
