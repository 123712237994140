/* eslint-disable */
import React, { useMemo, useContext, useEffect } from 'react';

import { IconButton, Tooltip, Grid } from '@material-ui/core';
import styled from '@emotion/styled';
import {
    LocalShippingOutlined as LocalShippingOutlinedIcon,
    Clear as ClearIcon,
    Lock as LockIcon,
    Event as EventIcon,
} from '@material-ui/icons';

import {
    Body1,
    Body2,
    RouteCardGrayText,
    GridItemRow as BaseRow,
    ConfirmationBadge,
    iconButtonCSS,
    PlanningListItem,
    JiraTitleField,
} from '../blocks';
import { css } from '@emotion/react';
import { MODALS, ROUTE_COLORS } from '../constants';
import routeStatusOptions from '../../../constants/routeStatusOptions';
import { useCustomerStops, useCallbacks, useRouteTimeLimits } from '../hooks';
import { useTotalWeight, useTotalCubes, useTotalRevenue, useRouteRevenue } from '@/hooks';
import { isOrderCancelled } from '../util/isOrderCancelled';
import { PlanningContext } from '../context';
import { percentFormatter, poundFormatter, integerFormatter } from '@/constants/formats';
import { useClientUser } from '@/hooks/useClientUser';
import { delWindowToFormattedDateRange } from '@/utilities/delWindowToFormattedDate';
import { ROUTE_STATUS_SUBSCRIPTION } from '../graphql/queries';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { colors } from '@/styles';
import FTLStopHelpers from '@/utilities/FTLStopHelpers';
import { calcStopTruckCubes, calcStopVolumes } from '@/utilities/calculateTruckStopCubes';
import { genAttributes } from '@onward-delivery/core';
const GridItemRow = styled(BaseRow)`
    white-space: nowrap;
    flex-direction: row;
    flex: 0;
    flex-basis: 0;
    margin: 0;
`;

const StatusRow = styled(BaseRow)`
    white-space: nowrap;
    justify-content: flex-start;
    flex-direction: row;
    flex: 1;
    height: 42px;
    flex-basis: 0;
`;

export default function RouteListItem({ route, routeIndex, drivers, subscribeToMore, style }) {
    const ordersByKey = useMemo(() => {
        return Object.fromEntries(
            (route?.orders || []).map((mapping) => [
                mapping.order_id,
                { ...mapping.order, routeMappingType: mapping.type },
            ])
        );
    }, [route]);

    const {
        circles,
        partner_carriers,
        trucks,
        preferences_route_time_limit,
        preferences_route_eod_limit,
        preferences_route_eod_limit_next_day,
        preferences_bypass_optimize,
    } = useClientUser();
    const { selectedRoute, setSelectedRoute, setModalOpen, setNotification, setActionableRoute } =
        useContext(PlanningContext);

    useEffect(() => {
        let unsubscribe;

        unsubscribe = subscribeToMore({
            document: ROUTE_STATUS_SUBSCRIPTION,
            variables: { route_id: route.route_id },
        });

        // Unsubscribe
        if (unsubscribe) return () => unsubscribe();
    }, [route, subscribeToMore]);

    const confirmationStatus = useMemo(() => {
        return (route.stopsByRouteId || []).reduce((status, stop) => {
            if (!FTLStopHelpers.isCustomerStop(stop, route)) {
                if (status) return status;
                return stop.del_window_start ? 'confirmed' : 'pending';
            }
            const stopStatus = (stop.orders || []).reduce((stopStatus, order_id) => {
                const order = ordersByKey[order_id];
                if (order?.order_status === 'cancelled') return stopStatus;
                if (!stopStatus) return order?.delivery_time_confirmed || 'pending';
                if (stopStatus === 'rejected' || order?.delivery_time_confirmed === 'rejected') return 'rejected';
                if (stopStatus === 'confirmed' && order?.delivery_time_confirmed === 'confirmed') return 'confirmed';
                return 'pending';
            }, null);

            if (!status) return stopStatus || 'pending';
            if (status === 'rejected' || stopStatus === 'rejected') return 'rejected';
            if (status === 'confirmed' && stopStatus === 'confirmed') return 'confirmed';
            return 'pending';
        }, null);
    }, [ordersByKey, route]);

    const routeName = route?.route_alias
        ? route.route_alias
        : isFinite(route.route_number)
        ? `Route ${route.route_number}`
        : '--';

    const totalRevenue = useRouteRevenue(route);
    const totalWeight = useTotalWeight(Object.values(ordersByKey));
    const totalCubes = useTotalCubes(Object.values(ordersByKey));
    const { updateRouteAlias } = useCallbacks();
    const customerStops = useCustomerStops(route);

    const setShowRemoveRouteModal = () => {
        if (
            route.status !== routeStatusOptions.PLANNING.value &&
            !(route.stopsByRouteId || []).some((s) => s.stop_completion_time)
        ) {
            setNotification({
                severity: 'warning',
                message: 'Cannot remove route that is in progress or complete.',
            });
        } else {
            setActionableRoute(route);
            setModalOpen(MODALS.ROUTE_REMOVE);
        }
    };

    const setShowRescheduleModal = () => {
        if (route.status !== routeStatusOptions.PLANNING.value || route.sending_to) {
            setNotification({
                severity: 'warning',
                message: 'Cannot reschedule a route that has already been submitted.',
            });
        } else {
            setActionableRoute(route);
            setModalOpen(MODALS.ROUTE_RESCHEDULE);
        }
    };

    const setShowUnlockModal = () => {
        if (route.status === 'complete') {
            setNotification({
                severity: 'warning',
                message: 'This route has already been completed.',
            });
        } else {
            setActionableRoute(route);
            setModalOpen(MODALS.ROUTE_UNLOCK);
        }
    };

    const routeExceedsTimeLimits = useRouteTimeLimits(
        route,
        preferences_route_time_limit,
        preferences_route_eod_limit,
        preferences_route_eod_limit_next_day
    );

    const firstOrder = Object.values(ordersByKey)[0] || {};
    const sortedStops = FTLStopHelpers.getStopSequence(route.stopsByRouteId);
    const stopSequenceEnriched = useMemo(() => {
        if (!sortedStops) return [];
        let enrichedStops = sortedStops.map((stop) => {
            const stopOrders = (stop.orders || []).map((order_id) => ordersByKey[order_id]).filter((x) => x);
            return {
                ...stop,
                orders: stopOrders,
                exchangeCubes: calcStopVolumes(stopOrders),
            };
        });
        return calcStopTruckCubes(enrichedStops);
    }, [route, ordersByKey, sortedStops]);

    const peakVolume = useMemo(() => {
        const maxTruckCubes = Math.max(...stopSequenceEnriched.map((stop) => stop.totalTruckCubes));
        return isNaN(maxTruckCubes) ? totalCubes : maxTruckCubes;
    }, [stopSequenceEnriched, totalCubes]);

    const routeTimeframe = useMemo(() => {
        const { zip } = genAttributes(firstOrder);
        const firstStop = sortedStops[0];
        const lastStop = sortedStops[sortedStops.length - 1];
        return delWindowToFormattedDateRange(
            firstStop?.stop_start_time,
            lastStop?.end ? lastStop?.stop_start_time : lastStop?.del_window_end,
            firstOrder?.[zip]
        );
    }, [firstOrder, route, sortedStops]);

    const renderConfirmationStatus = () => {
        if (confirmationStatus === 'rejected') {
            return (
                <StatusRow>
                    <ConfirmationBadge status="rejected">1 or more confirmations rejected</ConfirmationBadge>
                </StatusRow>
            );
        }

        if (route.need_to_optimize && (!preferences_bypass_optimize || route.sending_to === 'marketplace')) {
            return (
                <StatusRow>
                    <ConfirmationBadge status="pending">Route modified, map and resubmit</ConfirmationBadge>
                </StatusRow>
            );
        }

        if (route.need_to_submit && route.sending_to) {
            return (
                <StatusRow>
                    <ConfirmationBadge status="pending">Route modified, please resubmit</ConfirmationBadge>
                </StatusRow>
            );
        }

        if (confirmationStatus === 'pending' && route.sending_to) {
            return (
                <StatusRow>
                    <ConfirmationBadge status="pending">Pending Customer Confimation</ConfirmationBadge>
                </StatusRow>
            );
        }

        if (confirmationStatus === 'confirmed') {
            return route.status !== 'planning' ? (
                <StatusRow>
                    <ConfirmationBadge status="confirmed">All customer timeframes confirmed</ConfirmationBadge>
                </StatusRow>
            ) : (
                <StatusRow>
                    <ConfirmationBadge status="pending">Timeframes confirmed, please resubmit</ConfirmationBadge>
                </StatusRow>
            );
        }

        return (
            <StatusRow>
                <ConfirmationBadge status="pending">Submit route to confirm timeframes</ConfirmationBadge>
            </StatusRow>
        );
    };

    const renderSendTo = () => {
        switch (route.sending_to) {
            case 'partner':
                return <Body2>(Sent to {partneredCarrier?.business_name})</Body2>;
            case 'marketplace':
                return <Body2>(Sent to Onward)</Body2>;
            case 'internal':
                return <Body2>(Sent to Internal)</Body2>;
            default:
                return <Body2>(Pending)</Body2>;
        }
    };

    const partneredCarrier = useMemo(() => {
        return partner_carriers ? partner_carriers.find((_carrier) => _carrier.client_id === route?.carrier_id) : null;
    }, [route, partner_carriers]);

    const partneredDriver = useMemo(() => {
        return (
            partneredCarrier?.user?.teammatesByLeaderId?.find((i) => i?.teammate_id === route?.driver_id)?.username ||
            null
        );
    }, [partneredCarrier]);
    const driver = useMemo(() => {
        return drivers.find((driver) => route?.driver_id === driver.teammate_id)?.username || null;
    }, [drivers, route?.driver_id]);

    const truck = useMemo(() => {
        return trucks?.find((truck) => truck?.truck_id === route?.truck_id);
    }, [route, trucks]);

    return (
        <PlanningListItem
            style={style}
            hover={true}
            onClick={() => {
                setSelectedRoute((prev) => (prev === route.route_id ? null : route.route_id));
            }}
            selected={route.route_id === selectedRoute}
        >
            <GridItemRow
                css={css`
                    flex: 0;
                    flex-basis: 0;
                `}
            >
                <Grid
                    item
                    css={css`
                        flex-grow: 1;
                    `}
                >
                    <JiraTitleField
                        css={css`
                            width: 100%;
                            .MuiInputBase-root input {
                                border-radius: 3px;
                                border: 1px solid transparent;
                                text-align: left;
                                // background: #ececec;
                                margin-left: -5px;
                                padding-left: 5px;
                                padding-right: 5px;
                            }

                            .MuiInputBase-root input:hover,
                            .MuiInputBase-root input:focus {
                                background: #fff;
                                border-radius: 3px;
                            }
                        `}
                        placeholder={routeName}
                        defaultValue={routeName}
                        onBlur={(e) => updateRouteAlias(route, e.target.value)}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    />
                </Grid>
                <Grid
                    direction="row"
                    container
                    css={css`
                        justify-content: flex-end;
                        width: fit-content;
                    `}
                >
                    {routeExceedsTimeLimits && (
                        <Grid item>
                            <ReportProblemOutlinedIcon
                                css={css`
                                    color: ${colors.reds.primary};
                                    margin-left: 4px;
                                    margin-right: 4px;
                                `}
                            />
                        </Grid>
                    )}
                    {route.status !== routeStatusOptions.PLANNING.value && (
                        <Grid item>
                            <Tooltip
                                title={
                                    route.status === 'inProgress' || route.status === 'complete'
                                        ? "Can't unlock started routes"
                                        : 'unlock to edit'
                                }
                                placement="bottom"
                                arrow
                            >
                                <IconButton
                                    css={iconButtonCSS}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setShowUnlockModal();
                                    }}
                                >
                                    <LockIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    )}
                    {circles?.['plan-reschedule-v1'] &&
                        route.status === routeStatusOptions.PLANNING.value &&
                        !route.sending_to && (
                            <Grid item>
                                <IconButton
                                    css={iconButtonCSS}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setShowRescheduleModal();
                                    }}
                                >
                                    <EventIcon />
                                </IconButton>
                            </Grid>
                        )}
                    {!route?.shell && (
                        <Grid item>
                            <IconButton
                                css={iconButtonCSS}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowRemoveRouteModal();
                                }}
                            >
                                <ClearIcon />
                            </IconButton>
                        </Grid>
                    )}
                </Grid>
            </GridItemRow>
            <Grid
                direction="row"
                container
                css={css`
                    flex: 1;
                    flex-basis: 0;
                `}
            >
                <Grid
                    direction="column"
                    container
                    css={css`
                        justify-content: center;
                    `}
                >
                    <GridItemRow>
                        {renderSendTo()}

                        {totalRevenue && totalRevenue > 0 ? (
                            <Grid
                                item
                                css={css`
                                    display: flex;
                                    align-items: center;
                                `}
                            >
                                <Tooltip title="Total dispatcher revenue for the route. May not represent the full amount if you haven't entered revenue numbers for each order. Only includes revenue for orders that will interact with the end customer on this route. (ex. cross-docked orders on their non-customer facing leg excluded.)">
                                    <InfoOutlinedIcon className="mx-1" fontSize="small" />
                                </Tooltip>
                                <Body1>${totalRevenue}</Body1>
                            </Grid>
                        ) : null}
                    </GridItemRow>

                    <GridItemRow>
                        <Grid>
                            <LocalShippingOutlinedIcon
                                style={{ color: route?.route_color || ROUTE_COLORS[routeIndex] }}
                            />{' '}
                            <Body2 style={{ color: peakVolume > (truck?.truck_capacity || 1200) ? 'red' : 'inherit' }}>
                                {percentFormatter.format(
                                    peakVolume / (truck?.truck_capacity ? truck?.truck_capacity : 1200)
                                )}{' '}
                                full
                            </Body2>
                        </Grid>
                        <Body2>
                            {`${integerFormatter.format(totalCubes)} cu ft/ ${poundFormatter.format(totalWeight)}`}
                        </Body2>
                        <Body2>{`${customerStops} stop${customerStops !== 1 ? 's' : ''}`}</Body2>
                    </GridItemRow>
                    <GridItemRow>
                        <Body2>
                            Driver:{' '}
                            {route?.driver_id ? (
                                driver || partneredDriver
                            ) : (
                                <RouteCardGrayText>Unassigned</RouteCardGrayText>
                            )}
                        </Body2>
                    </GridItemRow>
                    <GridItemRow>
                        <Body2>
                            Truck: <RouteCardGrayText>{truck?.truck_name || 'Unassigned'}</RouteCardGrayText>
                        </Body2>
                    </GridItemRow>

                    <GridItemRow
                        css={css`
                            max-height: 42px;
                            flex-basis: auto;
                            justify-content: flex-start;
                            height: ${routeTimeframe ? 'auto' : '42px'};
                        `}
                    >
                        <Body2
                            css={css`
                                white-space: pre-wrap;
                            `}
                        >
                            {routeTimeframe ? routeTimeframe : <RouteCardGrayText>No timeframe</RouteCardGrayText>}
                        </Body2>
                    </GridItemRow>

                    {renderConfirmationStatus()}
                </Grid>
            </Grid>
        </PlanningListItem>
    );
}
