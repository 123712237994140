import React, { useContext, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { iconcss, LinkContainer, LinkLabel, lockiconcss, SublinkIcon } from './blocks';
import { css } from '@emotion/react';
import { KeyboardArrowDown, KeyboardArrowUp, LockOutlined } from '@material-ui/icons';
import { UserContext } from '../App';
import { Popover, Tooltip } from '@material-ui/core';
import { useClientUser } from '@/hooks';
import { colors } from '@/styles';

const NavLink = ({ link }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { sidebarCollapsed } = useContext(UserContext);
    const { circles } = useClientUser();

    const sublinks = useMemo(() => {
        return (link?.sublinks || []).map((sublink) => {
            let locked = false;
            if (sublink.feature) {
                if (Array.isArray(sublink.feature)) {
                    locked = !sublink.feature.some((flag) => circles?.[flag]);
                } else {
                    locked = !circles?.[sublink.feature];
                }
            }
            if (sublink?.restrictionFeature && sublink?.restrictionFeature?.some((feat) => circles?.[feat])) {
                locked = true;
            }
            return { ...sublink, locked };
        });
    }, [link, circles]);

    // Current link or one of its sublinks is selected
    const selected = useMemo(() => {
        return sublinks.length
            ? sublinks.find((sublink) => sublink.path === location.pathname)
            : location.pathname === link.path;
    }, [link, sublinks, location.pathname]);

    // Auto open sublinks if one is selected
    const [sublinksOpen, setSublinksOpen] = useState(sublinks && selected);
    const [anchorEl, setAnchorEl] = useState(null);

    // Open sublinks or navigate to single link path
    const handleSelect = () => {
        if (link.locked) return;

        if (sublinks.length > 0) {
            setSublinksOpen(!sublinksOpen);
            if (sublinks.length === 1 && !sublinksOpen) {
                navigate(sublinks[0].path);
            }
        } else {
            navigate(link.path);
        }
    };

    // Open collapsed sublink menu or navigate to single link path
    const handleSelectCollapsed = (event) => {
        if (link.locked) return;
        if (sublinks.length > 0) {
            setAnchorEl(event.currentTarget);
        } else {
            navigate(link.path);
        }
    };

    // Navigate to sublink path
    const handleSublinkSelect = (sublink) => () => {
        if (sublink.locked) return;
        navigate(sublink.path);
    };

    // Close collapsed sublink menu
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const isOnwardGreenLink = link.onwardGreen && !link.locked;

    return sidebarCollapsed ? (
        <>
            <Tooltip title={link.locked && link.lockedTooltipText ? link.lockedTooltipText : ''} placement="right">
                <LinkContainer
                    onClick={handleSelectCollapsed}
                    selected={selected}
                    locked={link.locked}
                    css={
                        isOnwardGreenLink &&
                        css`
                            background-color: ${colors.greens.primary};
                            color: white;
                            border-radius: 5px;
                            margin: 5px;
                            &:hover {
                                background-color: #3d8f45;
                            }
                        `
                    }
                >
                    <link.Icon css={iconcss} />
                </LinkContainer>
            </Tooltip>
            {sublinks.length > 0 && !link.locked && (
                <Popover
                    anchorEl={anchorEl}
                    open={!!anchorEl}
                    onClose={handleMenuClose}
                    css={css`
                        .MuiPaper-root {
                            background-color: ${isOnwardGreenLink ? colors.greens.primary : 'rgb(12, 11, 29)'};
                        }
                        .MuiMenu-list {
                            padding: 0;
                        }
                        .MuiMenuItem-root {
                            padding: 0;
                        }
                    `}
                >
                    {sublinks.map((sublink) => (
                        <LinkContainer
                            selected={selected?.path === sublink.path}
                            onClick={handleSublinkSelect(sublink)}
                            key={`sublink-${sublink.path}`}
                            css={css`
                                width: 100%;
                                padding: 8px;
                            `}
                        >
                            <LinkLabel selected={selected?.path === sublink.path}>{sublink.label}</LinkLabel>
                            {sublink.locked && <LockOutlined css={lockiconcss} />}
                        </LinkContainer>
                    ))}
                </Popover>
            )}
        </>
    ) : (
        <div
            css={
                isOnwardGreenLink &&
                css`
                    background-color: ${colors.greens.primary};
                    border-radius: 5px;
                    margin: 5px;
                    &:hover {
                        background-color: #3d8f45;
                    }
                `
            }
        >
            <Tooltip title={link.locked && link.lockedTooltipText ? link.lockedTooltipText : ''} placement="right">
                <LinkContainer
                    css={
                        isOnwardGreenLink &&
                        css`
                            border-radius: 5px;
                        `
                    }
                    onClick={handleSelect}
                    selected={selected}
                    isOnwardGreenLink={isOnwardGreenLink}
                    locked={link.locked}
                >
                    <link.Icon css={iconcss} />

                    <LinkLabel selected={selected}>{link.label}</LinkLabel>

                    {link.locked && <LockOutlined css={lockiconcss} />}

                    {sublinks.length > 0 && !link.locked && (
                        <div
                            css={css`
                                flex-grow: 1;
                                color: ${selected ? '#eee' : '#ffffff80'};
                                display: flex;
                                justify-content: flex-end;
                                border-radius: 5px;
                            `}
                        >
                            {sublinksOpen ? <KeyboardArrowUp css={iconcss} /> : <KeyboardArrowDown css={iconcss} />}
                        </div>
                    )}
                </LinkContainer>
            </Tooltip>
            {sublinks.length > 0 && sublinksOpen && (
                <>
                    {sublinks.map((sublink) => (
                        <LinkContainer
                            selected={selected?.path === sublink.path}
                            onClick={handleSublinkSelect(sublink)}
                            key={`sublink-${sublink.path}`}
                            isOnwardGreenLink={isOnwardGreenLink}
                            locked={sublink.locked}
                            css={css`
                                background-color: ${isOnwardGreenLink && selected?.path === sublink.path
                                    ? '#3D8F45'
                                    : ''};
                            `}
                        >
                            <SublinkIcon />
                            <LinkLabel selected={selected?.path === sublink.path}>{sublink.label}</LinkLabel>
                            {sublink.locked && <LockOutlined css={lockiconcss} />}
                        </LinkContainer>
                    ))}
                </>
            )}
        </div>
    );
};

export default NavLink;
